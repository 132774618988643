import { ResponseStatus } from '@/enums/ResponseStatus'
import UserInfo from '@/models/UserInfo'

export default class UserInfoResponse {
  public status: ResponseStatus = ResponseStatus.FAILED
  public is401: boolean = false
  public userInfo: UserInfo = new UserInfo()

  constructor (status: ResponseStatus, is401: boolean, userInfo: UserInfo) {
    this.status = status
    this.userInfo = userInfo
    this.is401 = is401
  }

  public static buildSuccesResponse (userInfo: UserInfo): UserInfoResponse {
    return new UserInfoResponse(ResponseStatus.SUCCESS, false, userInfo)
  }

  public static buildFailedResponse (statusCode: number): UserInfoResponse {
    return new UserInfoResponse(ResponseStatus.FAILED, statusCode === 401, new UserInfo())
  }
}
