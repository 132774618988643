import {JsonObject, JsonProperty} from 'json2typescript'

@JsonObject('Address')
export class Address {
    @JsonProperty('formatted')
    public formatted: string = ''

    @JsonProperty('street_address')
    public streetAddress: string = ''

    @JsonProperty('locality')
    public locality: string = ''

    @JsonProperty('region')
    public region: string = ''

    @JsonProperty('postal_code')
    public postalCode: string = ''

    @JsonProperty('country')
    public country: string = ''
}
