import { JsonObject, JsonProperty } from 'json2typescript'
import { Address } from '@/models/Address'

@JsonObject('UserInfo')
export default class UserInfo {
  @JsonProperty('sub')
  public sub: string = ''

  @JsonProperty('name')
  public name: string = ''

  @JsonProperty('given_name')
  public givenName: string = ''

  @JsonProperty('family_name')
  public familyName: string = ''

  @JsonProperty('middle_name')
  public middleName: string = ''

  @JsonProperty('nickname')
  public nickname: string = ''

  @JsonProperty('preferred_username')
  public preferredUsername: string = ''

  @JsonProperty('profile')
  public profile: string = ''

  @JsonProperty('picture')
  public picture: string = ''

  @JsonProperty('website')
  public website: string = ''

  @JsonProperty('email')
  public email: string = ''

  @JsonProperty('email_verified')
  public emailVerified: boolean = false

  @JsonProperty('gender')
  public gender: string = ''

  @JsonProperty('birthdate')
  public birthdate: string = ''

  @JsonProperty('zoneinfo')
  public zoneinfo: string = ''

  @JsonProperty('locale')
  public locale: string = ''

  @JsonProperty('phone_number')
  public phoneNumber: string = ''

  @JsonProperty('phone_number_verified')
  public phoneNumberVerified: boolean = false

  @JsonProperty('address')
  public address: Address = new Address()

  @JsonProperty('updated_at')
  public updatedAt: number = 0
}
