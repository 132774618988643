import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_fab = _resolveComponent("v-fab")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_AddEditUser = _resolveComponent("AddEditUser")!
  const _component_DeleteUser = _resolveComponent("DeleteUser")!
  const _component_LinkGroupLinkUser = _resolveComponent("LinkGroupLinkUser")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createBlock(_component_v_data_table, {
    modelValue: _ctx.selected,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selected) = $event)),
    headers: _ctx.headers(),
    items: _ctx.items,
    search: _ctx.search,
    "items-per-page": 10,
    "item-key": "id",
    class: "elevation-2",
    "show-select": "",
    "sort-by": [{ key: 'lastname', order: 'asc' }, { key: 'firstname', order: 'asc' }],
    "items-per-page-text": _ctx.i18n.t('pagination.perpage'),
    "items-per-page-options": [{title: '10', value: 10}, {title: '20', value: 20} , {title: _ctx.i18n.t('pagination.all'), value: -1}],
    "page-text": '{0} - {1} ' + _ctx.i18n.t('pagination.of') + ' {2}'
  }, {
    top: _withCtx(() => [
      _createVNode(_component_v_toolbar, { flat: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.i18n.t('pages.users')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          (_ctx.selected.length > 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.action,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.action) = $event)),
                  label: _ctx.i18n.t('fields.bulkaction'),
                  items: _ctx.actions(),
                  "single-line": "",
                  "hide-details": "",
                  clearable: ""
                }, null, 8, ["modelValue", "label", "items"]),
                _createVNode(_component_v_btn, {
                  class: "ml-4",
                  variant: "elevated",
                  theme: _ctx.userStore.mode==='light'?'dark':'light',
                  left: "",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.executeBulkAction()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('buttons.bulkaction')), 1)
                  ]),
                  _: 1
                }, 8, ["theme"])
              ], 64))
            : _createCommentVNode("", true),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event)),
            "append-icon": "mdi-magnify",
            label: _ctx.i18n.t('fields.search'),
            "single-line": "",
            "hide-details": ""
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_v_tooltip, {
            text: _ctx.i18n.t('buttons.addUser')
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_fab, _mergeProps(props, {
                absolute: "",
                location: "end",
                variant: "elevated",
                style: {"max-width":"64px","margin-right":"8px"},
                theme: _ctx.userStore.mode==='light'?'dark':'light',
                icon: "mdi-plus",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addItem()))
              }), null, 16, ["theme"])
            ]),
            _: 1
          }, 8, ["text"]),
          (_ctx.activeDialog !== _ctx.ActiveDialog.NONE)
            ? (_openBlock(), _createBlock(_component_v_dialog, {
                key: 1,
                modelValue: _ctx.showDialog,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showDialog) = $event)),
                "max-width": "500px"
              }, {
                default: _withCtx(() => [
                  (_ctx.activeDialog === _ctx.ActiveDialog.ADD || _ctx.activeDialog === _ctx.ActiveDialog.EDIT)
                    ? (_openBlock(), _createBlock(_component_AddEditUser, {
                        key: 0,
                        user: _ctx.editedItem,
                        onCloseDialog: _cache[4] || (_cache[4] = ($event: any) => {_ctx.activeDialog = _ctx.ActiveDialog.NONE; _ctx.updateUI()})
                      }, null, 8, ["user"]))
                    : _createCommentVNode("", true),
                  (_ctx.activeDialog === _ctx.ActiveDialog.DELETE)
                    ? (_openBlock(), _createBlock(_component_DeleteUser, {
                        key: 1,
                        user: _ctx.editedItem,
                        onCloseDialog: _cache[5] || (_cache[5] = ($event: any) => {_ctx.activeDialog = _ctx.ActiveDialog.NONE; _ctx.updateUI()})
                      }, null, 8, ["user"]))
                    : _createCommentVNode("", true),
                  (_ctx.activeDialog === _ctx.ActiveDialog.LINK || _ctx.activeDialog === _ctx.ActiveDialog.GROUPLINK)
                    ? (_openBlock(), _createBlock(_component_LinkGroupLinkUser, {
                        key: 2,
                        user: _ctx.editedItem,
                        users: _ctx.selected,
                        "active-dialog": _ctx.activeDialog,
                        onCloseDialog: _cache[6] || (_cache[6] = ($event: any) => {_ctx.activeDialog = _ctx.ActiveDialog.NONE; _ctx.updateUI()})
                      }, null, 8, ["user", "users", "active-dialog"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    "item.password": _withCtx(() => _cache[9] || (_cache[9] = [
      _createTextVNode(_toDisplayString('*******'))
    ])),
    "item.admin": _withCtx(({ item }) => [
      _createVNode(_component_v_icon, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.admin ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'), 1)
        ]),
        _: 2
      }, 1024)
    ]),
    "item.approved": _withCtx(({ item }) => [
      _createVNode(_component_v_icon, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.approved ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'), 1)
        ]),
        _: 2
      }, 1024)
    ]),
    "item.disabled": _withCtx(({ item }) => [
      _createVNode(_component_v_icon, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.disabled ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'), 1)
        ]),
        _: 2
      }, 1024)
    ]),
    "item.locked": _withCtx(({ item }) => [
      _createVNode(_component_v_icon, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.locked ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'), 1)
        ]),
        _: 2
      }, 1024)
    ]),
    "item.lastlogin": _withCtx(({ item }) => [
      _createTextVNode(_toDisplayString(item.lastlogin === -1 ? '-' : new Date(item.lastlogin).toLocaleDateString('nl-NL') + ' ' + new Date(item.lastlogin).toLocaleTimeString('nl-NL')), 1)
    ]),
    "item.actions": _withCtx(({ item }) => [
      _createVNode(_component_v_icon, {
        color: "info",
        small: "",
        class: "mr-2",
        onClick: ($event: any) => (_ctx.linkItem(item))
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createTextVNode(" mdi-link-variant ")
        ])),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_v_icon, {
        color: "success",
        small: "",
        class: "mr-2",
        onClick: ($event: any) => {_ctx.editedItem = item; _ctx.activeDialog = _ctx.ActiveDialog.EDIT}
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [
          _createTextVNode(" mdi-pencil ")
        ])),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_v_icon, {
        color: "error",
        small: "",
        onClick: ($event: any) => {_ctx.editedItem = item; _ctx.activeDialog = _ctx.ActiveDialog.DELETE}
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [
          _createTextVNode(" mdi-delete ")
        ])),
        _: 2
      }, 1032, ["onClick"])
    ]),
    _: 1
  }, 8, ["modelValue", "headers", "items", "search", "items-per-page-text", "items-per-page-options", "page-text"]))
}