<template>
  <v-card>
    <v-card-title>{{ $t('forms.deleteuser') }}</v-card-title>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        @click="$emit('closeDialog')"
      >
        {{ $t('buttons.cancel') }}
      </v-btn>
      <v-btn
        outlined
        @click="deleteUser()"
      >
        {{ $t('buttons.delete') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" src="./DeleteUser.ts" />
