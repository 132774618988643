import {defineComponent, onMounted, ref} from 'vue'
import {useI18n} from "vue-i18n";
import Response from "@/models/Response";
import {ResponseStatus} from "@/enums/ResponseStatus";
import ServiceController from "@/controllers/ServiceController";
import Service from "@/models/Service";

export default defineComponent({
    name: 'AddEditService',
    props: {service: Service},
    emits: ['closeDialog'],
    setup(props, {emit}) {
        const i18n = useI18n();
        const activeService = ref(new Service());
        const save = async () => {
            let response: Response;
            if (activeService.value.id > -1) {
                response = await ServiceController.update(activeService.value)
                console.log(response.messageEN)
            } else {
                response = await ServiceController.create(activeService.value)
                console.log(response.messageEN)
            }

            if (response.status === ResponseStatus.SUCCESS) {
                emit('closeDialog');
            }
        }
        onMounted(() => {
            activeService.value = props.service as Service;
        })

        return {
            i18n,
            activeService: activeService,
            save
        }
    }
})
