<template>
  <v-data-table
    hover
    :headers="headers()"
    :items="items"
    :search="search"
    :items-per-page="10"
    class="elevation-2"
    :sort-by="[{ key: 'name', order: 'desc' }]"
    :items-per-page-text="i18n.t('pagination.perpage')"
    :items-per-page-options="[{title: '10', value: 10}, {title: '20', value: 20} , {title: i18n.t('pagination.all'), value: -1}]"
    :page-text="'{0} - {1} ' + i18n.t('pagination.of') + ' {2}'"
  >
    <template #top>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ i18n.t('pages.backups') }}
        </v-toolbar-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="i18n.t('fields.search')"
          single-line
          hide-details
          style="margin-right: 4px"
        />
        <v-tooltip :text="i18n.t('buttons.addBackup')">
          <template #activator="{ props }">
            <v-fab
              v-bind="props"
              absolute
              location="end"
              variant="elevated"
              style="max-width: 64px"
              :theme="userStore.mode==='light'?'dark':'light'"
              icon="mdi-plus"
              @click="addItem()"
            />
          </template>
        </v-tooltip>
        <v-tooltip :text="i18n.t('buttons.restoreBackupFromFile')">
          <template #activator="{ props }">
            <v-fab
              v-bind="props"
              absolute
              location="end"
              variant="elevated"
              style="max-width: 64px; margin-right: 8px"
              :theme="userStore.mode==='light'?'dark':'light'"
              icon="mdi-cloud-upload-outline"
              @click="restoreBackupFile()"
            />
          </template>
        </v-tooltip>

        <v-dialog
          v-if="activeDialog !== ActiveDialog.NONE"
          v-model="showDialog"
          max-width="500px"
          @keydown.esc="updateUI()"
        >
          <AddBackup
            v-if="activeDialog === ActiveDialog.ADD || activeDialog === ActiveDialog.EDIT"
            @close-dialog="activeDialog = ActiveDialog.NONE; updateUI()"
          />
          <RestoreBackup
            v-if="activeDialog === ActiveDialog.RESTORE"
            :restore-string="selectedBackup"
            @close-dialog="activeDialog = ActiveDialog.NONE; updateUI()"
          />
          <UploadAndRestoreBackup
            v-if="activeDialog === ActiveDialog.UPLOAD_AND_RESTORE"
            :restore-string="selectedBackup"
            @close-dialog="activeDialog = ActiveDialog.NONE; updateUI()"
          />
          <DeleteBackup
            v-if="activeDialog === ActiveDialog.DELETE"
            :description="selectedBackup"
            @close-dialog="activeDialog = ActiveDialog.NONE; updateUI()"
          />
        </v-dialog>
      </v-toolbar>
    </template>
    <template #item.actions="{ item }">
      <v-icon
        small
        color="success"
        @click="restoreItem(item.name)"
      >
        mdi-backup-restore
      </v-icon>
      <v-icon
        small
        color="error"
        @click="deleteItem(item.name)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script lang="ts" src="./BackupPage.ts"></script>
