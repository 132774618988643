<template>
  <v-data-table
    hover
    :headers="headers()"
    :items="items"
    :search="search"
    :items-per-page="10"
    item-key="id"
    class="elevation-2"
    :sort-by="[{ key: 'name', order: 'asc' }, { key: 'instance', order: 'asc' }]"
    :items-per-page-text="i18n.t('pagination.perpage')"
    :items-per-page-options="[{title: '10', value: 10}, {title: '20', value: 20} , {title: i18n.t('pagination.all'), value: -1}]"
    :page-text="'{0} - {1} ' + i18n.t('pagination.of') + ' {2}'"
  >
    <template #top>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ i18n.t('pages.services') }}
        </v-toolbar-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="i18n.t('fields.search')"
          single-line
          hide-details
          style="margin-right: 4px"
        /> <v-tooltip :text="i18n.t('buttons.addService')">
          <template #activator="{ props }">
            <v-fab
              v-bind="props"
              absolute
              location="end"
              variant="elevated"
              style="max-width: 64px; margin-right: 8px"
              :theme="userStore.mode==='light'?'dark':'light'"
              icon="mdi-plus"
              @click="addItem()"
            />
          </template>
        </v-tooltip>
        <v-dialog
          v-if="activeDialog !== ActiveDialog.NONE"
          v-model="showDialog"
          max-width="500px"
          @keydown.esc="updateUI()"
        >
          <AddEditService
            v-if="activeDialog === ActiveDialog.ADD || activeDialog === ActiveDialog.EDIT"
            :service="editedItem"
            @close-dialog="activeDialog = ActiveDialog.NONE; updateUI()"
          />
          <DeleteService
            v-if="activeDialog === ActiveDialog.DELETE"
            :service="editedItem"
            @close-dialog="activeDialog = ActiveDialog.NONE; updateUI()"
          />
        </v-dialog>
      </v-toolbar>
    </template>
    <template #item.forAllPolteq="{ item }">
      <v-icon>
        {{ item.forAllPolteq ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
      </v-icon>
    </template>
    <template #item.forAllLoggedIn="{ item }">
      <v-icon>
        {{ item.forAllLoggedIn ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
      </v-icon>
    </template>
    <template #item.actions="{ item }">
      <v-icon
        color="success"
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        color="error"
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script lang="ts" src="./ServicesPage.ts"></script>
