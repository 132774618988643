export enum ActiveDialog {
  ADD = 'Add',
  EDIT = 'Edit',
  DELETE = 'Delete',
  LINK = 'Link',
  GROUPLINK = 'GroupLink',
  RESTORE = 'Restore',
  UPLOAD_AND_RESTORE = 'Upload and restore',
  NONE = 'None'
}
