<template>
  <v-card>
    <v-card-title>{{ $t('forms.restoreBackupUpload') }}</v-card-title>
    <v-card-text>{{ $t('text.restoreBackupUpload') }} <br></v-card-text>

    <v-file-input
      v-model="file"
      dense
      outlined
      label="Choose file"
      prepend-icon="mdi-folder-upload-outline"
    />
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        @click="$emit('closeDialog')"
      >
        {{ $t('buttons.cancel') }}
      </v-btn>
      <v-btn
        outlined
        @click="restoreBackup()"
      >
        {{ $t('buttons.restoreBackup') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" src="./UploadAndRestoreBackup.ts" />
