import {defineComponent, onMounted, Ref, ref} from 'vue'
import {useUserStore} from '@/plugins/stores'
import {ActiveDialog} from '@/enums/ActiveDialog'
import {useI18n} from 'vue-i18n'
import Service from "@/models/Service";
import ServiceController from "@/controllers/ServiceController";
import AddEditService from "@/components/AddEditService/AddEditService.vue";
import DeleteService from "@/components/DeleteService/DeleteService.vue";

export default defineComponent({
    name: 'ServicesPage',
    components: {
        DeleteService,
        AddEditService
    },
    setup() {
        const i18n = useI18n();
        const activeDialog: Ref<ActiveDialog> = ref(ActiveDialog.NONE);
        const search = ref('');
        const headers = () => [
            {title: i18n.t('service.fields.name'), key: 'name'},
            {title: i18n.t('service.fields.instance'), key: 'instance'},
            {title: i18n.t('service.fields.secret'), key: 'secret', sortable: false},
            {title: i18n.t('service.fields.url'), key: 'url', sortable: false},
            {title: i18n.t('service.fields.gui'), key: 'gui', sortable: false},
            {title: i18n.t('service.fields.forAllPolteq'), key: 'forAllPolteq', sortable: false},
            {title: i18n.t('service.fields.forAllLoggedIn'), key: 'forAllLoggedIn', sortable: false},
            {title: i18n.t('table.columns.actions'), key: 'actions', sortable: false}
        ];
        const items = ref(new Array<Service>());
        const userStore = useUserStore();
        const editedItem = ref(new Service());
        const footerProps = {
            itemsPerPageAllText: i18n.t('pagination.all'),
            itemsPerPageText: i18n.t('pagination.perpage')
        };
        const formTitle = () => {
            return editedItem.value.id === -1 ? i18n.t('forms.newservice') : i18n.t('forms.edituser')
        };
        const close = () => {
            activeDialog.value = ActiveDialog.NONE;
            showDialog.value = false;
            editedItem.value = new Service();
        };
        const addItem = () => {
            activeDialog.value = ActiveDialog.ADD;
            showDialog.value = true;
            editedItem.value = new Service()
        };
        const editItem = (item: Service) => {
            activeDialog.value = ActiveDialog.EDIT;
            showDialog.value = true;
            editedItem.value = item
        };
        const deleteItem = (item: Service) => {
            activeDialog.value = ActiveDialog.DELETE;
            showDialog.value = true;
            editedItem.value = item;
        };
        const updateUI = async () => {
            close();
            items.value = await ServiceController.getAll();
        };
        const showDialog = ref(false);
        onMounted(async () => {
            await updateUI();
        });
        return {
            i18n,
            close,
            formTitle,
            addItem,
            editItem,
            deleteItem,
            footerProps,
            activeDialog,
            search,
            userStore,
            headers,
            items,
            editedItem,
            updateUI,
            showDialog
        }
    },
    computed: {
        ActiveDialog() {
            return ActiveDialog
        }
    }
})


