import { defineComponent, onMounted, ref } from 'vue'
import AdminController from '@/controllers/AdminController'

export default defineComponent({
  name: 'LastNewUser',
  setup () {
    const user = ref('')
    onMounted(async () => {
      user.value = await AdminController.getLastNewUser()
    })
    return {
      user
    }
  }
})
