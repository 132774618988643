export default class User {
  public id: number = -1
  public firstname: string = ''
  public lastname: string = ''
  public company: string = ''
  public email: string = ''
  public phonenumber: string = ''
  public password: string = ''
  public admin: boolean = false
  public approved: boolean = false
  public disabled: boolean = false
  public locked: boolean = false
  public lastlogin: number = -1
}
