import { defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import RouterLink from '@/models/RouterLink'
import { useRouter } from 'vue-router'
import AdminController from '@/controllers/AdminController'
import Settings from '@/config/Settings'
import { ResponseStatus } from '@/enums/ResponseStatus'
import { useUserStore } from '@/plugins/stores'
import UserInfo from '@/models/UserInfo'

export default defineComponent({
  name: 'AdminPortal',
  setup () {
    const router = useRouter()
    // const userStore = useUserStore();
    const i18n = useI18n()
    const routerlinks = ref(new Array<RouterLink>())

    const loginUrl = () => {
      return `${Settings.idUrl}/api/auth/admin/login?state=` + window.location.pathname
    }
    const userStore = useUserStore()
    const loggedIn = ref(false)
    onMounted(async () => {
      const response = await AdminController.getUserInfo()
      loggedIn.value = response.status === ResponseStatus.SUCCESS
      if (loggedIn.value) {
        userStore.userInfo = response.userInfo
      }
      setRouterLinks()
    })
    const setRouterLinks = () => {
      routerlinks.value = new Array<RouterLink>()
      routerlinks.value.push(new RouterLink('Home', 'mdi-home', '/'))
      if (loggedIn.value) {
        routerlinks.value.push({
          title: i18n.t('pages.services'),
          icon: 'mdi-table-multiple',
          ref: '/services'
        })
        routerlinks.value.push({
          title: i18n.t('pages.users'),
          icon: 'mdi-account-box-multiple-outline',
          ref: '/users'
        })
        routerlinks.value.push({
          title: i18n.t('pages.backups'),
          icon: 'mdi-file-multiple-outline',
          ref: '/backups'
        })
      }
    }
    const logout = async () => {
      await AdminController.logout()
      loggedIn.value = false
      userStore.userInfo = new UserInfo()
      setRouterLinks()
      await router.push('/')
    }
    return {
      router,
      i18n,
      routerlinks,
      loginUrl,
      loggedIn,
      logout,
      userStore
    }
  }
})
