<template>
  <v-card
    id="lastNewUserCard"
    width="25vw"
    min-height="75px"
    height="20vh"
    :elevation="2"
  >
    <v-card-title>Last new user:</v-card-title>
    <v-card-text>{{ user }}</v-card-text>
  </v-card>
</template>

<script lang="ts" src="./LastNewUser.ts" />
