export default class Service {
  public id: number = -1
  public name: string = ''
  public instance: string = ''
  public secret: string = ''
  public url: string = ''
  public gui: string = ''
  public forAllPolteq: boolean = false
  public forAllLoggedIn: boolean = false
}
