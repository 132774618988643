import {defineComponent, onMounted, ref} from 'vue'
import {useI18n} from "vue-i18n";
import {ResponseStatus} from "@/enums/ResponseStatus";
import AdminController from "@/controllers/AdminController";

export default defineComponent({
    name: 'DeleteBackup',
    props: {description: String},
    emits: ['closeDialog'],
    setup(props, {emit}) {
        const i18n = useI18n();
        const backupToDelete = ref('');
        const deleteBackup = async () => {
            const response = await AdminController.delete(backupToDelete.value);
            console.log(response.messageEN)
            if (response.status === ResponseStatus.SUCCESS) {
                emit('closeDialog');
            }
        };
        onMounted(() => {
            backupToDelete.value = props.description as string;
        })

        return {
            i18n,
            deleteBackup
        }
    }
})
