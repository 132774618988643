import { defineStore } from 'pinia'
import UserInfo from '@/models/UserInfo'
import Service from '@/models/Service'

export const useUserStore = defineStore('userStore', {
  state: () => {
    return {
      userInfo: new UserInfo(),
      services: new Array<Service>(),
      mode: 'light'
    }
  }
})

export const useTokenStore = defineStore('tokenStore', {
  state: () => {
    return {
      accessToken: '',
      idToken: '',
      refreshToken: ''
    }
  }
})
