export default class SelectableService {
  public id: number = -1
  public name: string = ''
  public instance: string = ''
  public link: boolean = false

  constructor (id: number, name: string, instance: string, link: boolean) {
    this.id = id
    this.name = name
    this.instance = instance
    this.link = link
  }
}
