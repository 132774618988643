<template>
  <v-card>
    <v-card-title>{{ $t('forms.restoreBackup') }}</v-card-title>
    <v-card-text>
      {{ $t('text.restoreBackup') }} <br>
      <b>{{ backupName }}</b>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        @click="$emit('closeDialog')"
      >
        {{ $t('buttons.cancel') }}
      </v-btn>
      <v-btn
        outlined
        @click="restoreBackup()"
      >
        {{ $t('buttons.restoreBackup') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" src="./RestoreBackup.ts" />
