import axios, { AxiosError } from 'axios'
import Service from '@/models/Service'
import Settings from '@/config/Settings'
import Response from '../models/Response'
import AxiosHelper from '@/util/AxiosHelper'

axios.defaults.withCredentials = true
axios.defaults.headers.common = { 'Content-Type': 'application/json' }

export default class ServiceController {
  private static clientId = '?clientId=ADMIN_PORTAL'

  public static async getAll (): Promise<Service[]> {
    return await axios
      .get(`${Settings.servicesUrl}${this.clientId}`)
      .then((response) => {
        if (response.status === 200) {
          return response.data as Service[]
        } else {
          return []
        }
      })
      .catch(() => {
        return []
      })
  }

  public static async getAllAvailable (): Promise<Service[]> {
    return await axios
      .get(`${Settings.servicesUrl}/allavailable${this.clientId}`)
      .then((response) => {
        if (response.status === 200) {
          return response.data as Service[]
        } else {
          return []
        }
      })
      .catch(() => {
        return []
      })
  }

  public static async create (service: Service): Promise<Response> {
    return await axios.post(`${Settings.servicesUrl}${this.clientId}`, service)
      .then((response) => {
        if (response.status === 201) {
          return Response.buildSuccesResponse(
            `Service ${service.name} ${service.instance} created successfully`,
            `Dienst ${service.name} ${service.instance} succesvol aangemaakt`)
        } else {
          return Response.buildFailedResponse(
            response.status,
            `Expected status 201 but got ${response.status}`,
            `Verwachte status is 201, maar ontvangen status is ${response.status}`
          )
        }
      })
      .catch((error: AxiosError) => {
        return AxiosHelper.handleErrorResponse(error)
      })
    // .catch((error) => {
    //   return Response.buildFailedResponse(
    //     error.status,
    //     `Failed to create service ${service.name} ${service.instance}`,
    //     `Dienst ${service.name} ${service.instance} aanmaken is mislukt`);
    // });
  }

  public static async update (service: Service): Promise<Response> {
    return await axios.put(`${Settings.servicesUrl}/${service.id}${this.clientId}`, service)
      .then((response) => {
        if (response.status === 200) {
          return Response.buildSuccesResponse(
            `Service ${service.name} ${service.instance} updated successfully`,
            `Dienst ${service.name} ${service.instance} succesvol aangepast`)
        } else {
          return Response.buildFailedResponse(
            response.status,
            `Expected status 200 but got ${response.status}`,
            `Verwachte status is 200, maar ontvangen status is ${response.status}`
          )
        }
      })
      .catch((error: AxiosError) => {
        return AxiosHelper.handleErrorResponse(error)
      })
    // .catch((error) => {
    //   return Response.buildFailedResponse(
    //     error.status,
    //     `Failed to update service ${service.name} ${service.instance}`,
    //     `Dienst ${service.name} ${service.instance} aanpassen is mislukt`);
    // });
  }

  public static async delete (id: number): Promise<Response> {
    return await axios.delete(`${Settings.servicesUrl}/${id}${this.clientId}`)
      .then((response) => {
        if (response.status === 204) {
          return Response.buildSuccesResponse(
            `Service with id ${id} deleted successfully`,
            `Dienst met id ${id} succesvol verwijderd`)
        } else {
          return Response.buildFailedResponse(
            response.status,
            `Expected status 204 but got ${response.status}`,
            `Verwachte status is 204, maar ontvangen status is ${response.status}`
          )
        }
      })
      .catch((error: AxiosError) => {
        return AxiosHelper.handleErrorResponse(error)
      })
    // .catch((error) => {
    //   return Response.buildFailedResponse(
    //     error.status,
    //     `Failed to delete service with id ${id}`,
    //     `Dienst met id ${id} verwijderen is mislukt`);
    // });
  }
}
