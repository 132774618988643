import {defineComponent, onMounted, ref} from 'vue'
import User from "@/models/User";
import {useI18n} from "vue-i18n";
import Response from "@/models/Response";
import UserController from "@/controllers/UserController";
import {ResponseStatus} from "@/enums/ResponseStatus";

export default defineComponent({
    name: 'AddEditUser',
    props: {user: User},
    emits: ['closeDialog'],
    setup(props, {emit}) {
        const i18n = useI18n();
        const activeUser = ref(new User());
        const save = async () => {
            let response: Response;
            if (activeUser.value.id > -1) {
                response = await UserController.update(activeUser.value)
                console.log(response.messageEN)
            } else {
                response = await UserController.create(activeUser.value)
                console.log(response.messageEN)
            }

            if (response.status === ResponseStatus.SUCCESS) {
                emit('closeDialog');
            }
        }
        onMounted(() => {
            activeUser.value = props.user as User;
        })

        return {
            i18n,
            activeUser,
            save
        }
    }
})
