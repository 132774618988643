import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('forms.restoreBackupUpload')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('text.restoreBackupUpload')) + " ", 1),
          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
        ]),
        _: 1
      }),
      _createVNode(_component_v_file_input, {
        modelValue: _ctx.file,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.file) = $event)),
        dense: "",
        outlined: "",
        label: "Choose file",
        "prepend-icon": "mdi-folder-upload-outline"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            outlined: "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeDialog')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('buttons.cancel')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            outlined: "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.restoreBackup()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('buttons.restoreBackup')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}