import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('forms.linkservice')), 1)
        ]),
        _: 1
      }),
      (_ctx.activeDialog === _ctx.ActiveDialog.LINK)
        ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.activeUser.firstname) + " " + _toDisplayString(_ctx.activeUser.lastname) + " (" + _toDisplayString(_ctx.activeUser.email) + ") ", 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.activeDialog === _ctx.ActiveDialog.GROUPLINK)
        ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 1 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.selected.length) + " " + _toDisplayString(_ctx.$t('text.multiuser')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.activeDialog === _ctx.ActiveDialog.GROUPLINK && _ctx.link)
        ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 2 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('text.link')), 1)
            ]),
            _: 1
          }))
        : (_ctx.activeDialog === _ctx.ActiveDialog.GROUPLINK && !_ctx.link)
          ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 3 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('text.unlink')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_data_table, {
            headers: _ctx.serviceHeaders(),
            items: _ctx.services,
            search: _ctx.search,
            "items-per-page": 10,
            "item-key": "id",
            density: "compact",
            class: "elevation-2",
            "items-per-page-text": _ctx.i18n.t('pagination.perpage'),
            "items-per-page-options": [{title: '10', value: 10}, {title: '20', value: 20} , {title: _ctx.i18n.t('pagination.all'), value: -1}],
            "page-text": '{0} - {1} ' + _ctx.i18n.t('pagination.of') + ' {2}'
          }, {
            top: _withCtx(() => [
              _createVNode(_component_v_toolbar, { flat: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_toolbar_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('pages.services')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_text_field, {
                    "v-model": _ctx.search,
                    "append-icon": "mdi-magnify",
                    label: _ctx.$t('fields.search'),
                    "single-line": "",
                    "hide-details": ""
                  }, null, 8, ["v-model", "label"])
                ]),
                _: 1
              })
            ]),
            "item.link": _withCtx(({ item }) => [
              _createVNode(_component_v_checkbox, {
                modelValue: item.link,
                "onUpdate:modelValue": ($event: any) => ((item.link) = $event),
                density: "compact"
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1
          }, 8, ["headers", "items", "search", "items-per-page-text", "items-per-page-options", "page-text"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            outlined: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeDialog')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('buttons.cancel')), 1)
            ]),
            _: 1
          }),
          (_ctx.activeDialog === _ctx.ActiveDialog.LINK)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                outlined: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.linkUser()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('buttons.link')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.activeDialog === _ctx.ActiveDialog.GROUPLINK)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 1,
                outlined: "",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.linkUsers()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('buttons.link')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}