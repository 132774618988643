import axios, { AxiosError } from 'axios'
import Response from '@/models/Response'

export default class AxiosHelper {
  public static handleErrorResponse (error: Error | AxiosError) {
    if (axios.isAxiosError(error) && error.response) {
      const data = error.response.data as Record<string, string>
      return Response.buildFailedResponse(data.code as unknown as number, data.messageEN, data.messageNL)
    } else {
      return Response.buildFailedResponse(-1,
        `Er is iets misgegaan: ${error.message}`,
        `Something went wrong: ${error.message}`
      )
    }
  }
}
