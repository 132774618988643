import {defineComponent, onMounted, Ref, ref} from 'vue'
import {useI18n} from "vue-i18n";
import {ResponseStatus} from "@/enums/ResponseStatus";
import Service from "@/models/Service";
import AdminController from "@/controllers/AdminController";

export default defineComponent({
    name: 'AddBackup',
    emits: ['closeDialog'],
    setup(props, {emit}) {
        const i18n = useI18n();
        const activeService = ref(new Service());
        const description: Ref<string> = ref('');
        const backup = async () => {
            const response = await AdminController.backup(description.value)
            console.log(response.messageEN)
            if (response.status === ResponseStatus.SUCCESS) {
                emit('closeDialog');
            }
        }
        onMounted(() => {
        })

        return {
            i18n,
            activeService: activeService,
            description,
            backup
        }
    }
})
