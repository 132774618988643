<template>
  <v-card
    id="sessionsPerServiceCard"
    width="25vw"
    min-height="75px"
    height="20vh"
    :elevation="2"
  >
    <v-card-title>Sessions per service:</v-card-title>
    <v-card-text>
      <template
        v-for="(value, propertyName) of sessionsPerService"
        :key="propertyName"
      >
        {{ propertyName }}: {{ value }} <br>
      </template>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" src="./SessionsPerService.ts" />
