import { defineComponent, onMounted, ref } from 'vue'
import AdminController from '@/controllers/AdminController'

export default defineComponent({
  name: 'IDSessions',
  setup () {
    const sessions = ref(new Array<string>())
    onMounted(async () => {
      sessions.value = await AdminController.getSSOSessions()
    })

    return {
      sessions
    }
  }
})
