import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_fab = _resolveComponent("v-fab")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_AddBackup = _resolveComponent("AddBackup")!
  const _component_RestoreBackup = _resolveComponent("RestoreBackup")!
  const _component_UploadAndRestoreBackup = _resolveComponent("UploadAndRestoreBackup")!
  const _component_DeleteBackup = _resolveComponent("DeleteBackup")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createBlock(_component_v_data_table, {
    hover: "",
    headers: _ctx.headers(),
    items: _ctx.items,
    search: _ctx.search,
    "items-per-page": 10,
    class: "elevation-2",
    "sort-by": [{ key: 'name', order: 'desc' }],
    "items-per-page-text": _ctx.i18n.t('pagination.perpage'),
    "items-per-page-options": [{title: '10', value: 10}, {title: '20', value: 20} , {title: _ctx.i18n.t('pagination.all'), value: -1}],
    "page-text": '{0} - {1} ' + _ctx.i18n.t('pagination.of') + ' {2}'
  }, {
    top: _withCtx(() => [
      _createVNode(_component_v_toolbar, { flat: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.i18n.t('pages.backups')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            "append-icon": "mdi-magnify",
            label: _ctx.i18n.t('fields.search'),
            "single-line": "",
            "hide-details": "",
            style: {"margin-right":"4px"}
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_v_tooltip, {
            text: _ctx.i18n.t('buttons.addBackup')
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_fab, _mergeProps(props, {
                absolute: "",
                location: "end",
                variant: "elevated",
                style: {"max-width":"64px"},
                theme: _ctx.userStore.mode==='light'?'dark':'light',
                icon: "mdi-plus",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addItem()))
              }), null, 16, ["theme"])
            ]),
            _: 1
          }, 8, ["text"]),
          _createVNode(_component_v_tooltip, {
            text: _ctx.i18n.t('buttons.restoreBackupFromFile')
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_fab, _mergeProps(props, {
                absolute: "",
                location: "end",
                variant: "elevated",
                style: {"max-width":"64px","margin-right":"8px"},
                theme: _ctx.userStore.mode==='light'?'dark':'light',
                icon: "mdi-cloud-upload-outline",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.restoreBackupFile()))
              }), null, 16, ["theme"])
            ]),
            _: 1
          }, 8, ["text"]),
          (_ctx.activeDialog !== _ctx.ActiveDialog.NONE)
            ? (_openBlock(), _createBlock(_component_v_dialog, {
                key: 0,
                modelValue: _ctx.showDialog,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showDialog) = $event)),
                "max-width": "500px",
                onKeydown: _cache[8] || (_cache[8] = _withKeys(($event: any) => (_ctx.updateUI()), ["esc"]))
              }, {
                default: _withCtx(() => [
                  (_ctx.activeDialog === _ctx.ActiveDialog.ADD || _ctx.activeDialog === _ctx.ActiveDialog.EDIT)
                    ? (_openBlock(), _createBlock(_component_AddBackup, {
                        key: 0,
                        onCloseDialog: _cache[3] || (_cache[3] = ($event: any) => {_ctx.activeDialog = _ctx.ActiveDialog.NONE; _ctx.updateUI()})
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.activeDialog === _ctx.ActiveDialog.RESTORE)
                    ? (_openBlock(), _createBlock(_component_RestoreBackup, {
                        key: 1,
                        "restore-string": _ctx.selectedBackup,
                        onCloseDialog: _cache[4] || (_cache[4] = ($event: any) => {_ctx.activeDialog = _ctx.ActiveDialog.NONE; _ctx.updateUI()})
                      }, null, 8, ["restore-string"]))
                    : _createCommentVNode("", true),
                  (_ctx.activeDialog === _ctx.ActiveDialog.UPLOAD_AND_RESTORE)
                    ? (_openBlock(), _createBlock(_component_UploadAndRestoreBackup, {
                        key: 2,
                        "restore-string": _ctx.selectedBackup,
                        onCloseDialog: _cache[5] || (_cache[5] = ($event: any) => {_ctx.activeDialog = _ctx.ActiveDialog.NONE; _ctx.updateUI()})
                      }, null, 8, ["restore-string"]))
                    : _createCommentVNode("", true),
                  (_ctx.activeDialog === _ctx.ActiveDialog.DELETE)
                    ? (_openBlock(), _createBlock(_component_DeleteBackup, {
                        key: 3,
                        description: _ctx.selectedBackup,
                        onCloseDialog: _cache[6] || (_cache[6] = ($event: any) => {_ctx.activeDialog = _ctx.ActiveDialog.NONE; _ctx.updateUI()})
                      }, null, 8, ["description"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    "item.actions": _withCtx(({ item }) => [
      _createVNode(_component_v_icon, {
        small: "",
        color: "success",
        onClick: ($event: any) => (_ctx.restoreItem(item.name))
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode(" mdi-backup-restore ")
        ])),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_v_icon, {
        small: "",
        color: "error",
        onClick: ($event: any) => (_ctx.deleteItem(item.name))
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createTextVNode(" mdi-delete ")
        ])),
        _: 2
      }, 1032, ["onClick"])
    ]),
    _: 1
  }, 8, ["headers", "items", "search", "items-per-page-text", "items-per-page-options", "page-text"]))
}