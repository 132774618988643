<template>
  <v-container>
    <v-row no-gutters>
      <v-col style="text-align: center">
        <h1>Polteq ID - Dashboard</h1>
      </v-col>
    </v-row>
    <v-row
      v-if="userStore.userInfo.email !== ''"
      no-gutters
    >
      <v-col>
        <IDSessions />
      </v-col>
      <v-col>
        <LastNewUser />
      </v-col>
      <v-col>
        <SessionsPerService />
      </v-col>
    </v-row>
  </v-container>
  <!--  Laatst toegevoegde service -->
  <!--  Uptime ID, User AdminPortal, Admin AdminPortal -->
  <!--  Gelogde errors / warnings -->
  <!--  Service health -->
</template>

<script lang="ts" src="./HomePage.ts" />
