import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

export default createVuetify({
  ssr: false,
  defaults: {
    global: {
      dark: false
    }
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          background: '#FFF',
          "on-background": '#033',
          surface: '#FFFFFF',
          'on-surface': '#033',
          'surface-light': '#bcd2d2',
          primary: '#6200EE',
          'primary-darken-1': '#3700B3',
          secondary: '#03DAC6',
          'secondary-darken-1': '#018786',
          error: '#a12424',
          'on-error': '#FFFFFF',
          info: '#2196F3',
          'on-info': '#FFFFFF',
          success: '#99CC00',
          'on-success': '#FFFFFF',
          warning: '#FB8C00',
          'on-warning': '#FFFFFF',
        }
      },
      dark: {
        dark: true,
        colors: {
          background: '#000',
          "on-background": '#033',
          surface: '#033',
          'on-surface': '#FFF',
          'surface-light': '#033',
          'on-surface-light': '#FFF',
          primary: '#6200EE',
          'primary-darken-1': '#3700B3',
          secondary: '#03DAC6',
          'secondary-darken-1': '#018786',
          error: '#a12424',
          'on-error': '#FFFFFF',
          info: '#2196F3',
          'on-info': '#FFFFFF',
          success: '#99CC00',
          'on-success': '#FFFFFF',
          warning: '#FB8C00',
          'on-warning': '#FFFFFF',
        },
        variables: {
          'border-color': '#000000',
          'border-opacity': 0.12,
          'high-emphasis-opacity': 0.84,
          'medium-emphasis-opacity': 0.60,
          'disabled-opacity': 0.38,
          'idle-opacity': 0.04,
          'hover-opacity': 0.04,
          'focus-opacity': 0.12,
          'selected-opacity': 0.08,
          'activated-opacity': 0.12,
          'pressed-opacity': 0.12,
          'dragged-opacity': 0.08,
          'theme-kbd': '#212529',
          'theme-on-kbd': '#FFFFFF',
          'theme-code': '#F5F5F5',
          'theme-on-code': '#000000',
        }
      }
    }
  },
  components,
  directives
})
