import { createI18n } from 'vue-i18n'

const translations = {
  nl: require('@/locale/nl.json') as Record<string, string>,
  en: require('@/locale/en.json') as Record<string, string>
}

const i18n = createI18n({
  legacy: false,
  fallbackLocale: 'nl',
  locale: 'nl',
  messages: translations
})

export default i18n
