import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    id: "sessionsPerServiceCard",
    width: "25vw",
    "min-height": "75px",
    height: "20vh",
    elevation: 2
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Sessions per service:")
        ])),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sessionsPerService, (value, propertyName) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: propertyName }, [
              _createTextVNode(_toDisplayString(propertyName) + ": " + _toDisplayString(value) + " ", 1),
              _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
            ], 64))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}