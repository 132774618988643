import { ResponseStatus } from '@/enums/ResponseStatus'

export default class Response {
  public status: ResponseStatus = ResponseStatus.FAILED
  public responseCode: number = -1
  public messageEN: string = ''
  public messageNL: string = ''

  constructor (status: ResponseStatus, messageEN: string, messageNL: string) {
    this.status = status
    this.messageEN = messageEN
    this.messageNL = messageNL
  }

  public static buildSuccesResponse (messageEN: string, messageNL: string): Response {
    return new Response(ResponseStatus.SUCCESS, messageEN, messageNL)
  }

  public static buildFailedResponse (responseCode: number, messageEN: string, messageNL: string): Response {
    return new Response(ResponseStatus.FAILED, messageEN, messageNL)
  }
}
