import {defineComponent, onMounted, Ref, ref} from 'vue'
import {useI18n} from "vue-i18n";
import {ResponseStatus} from "@/enums/ResponseStatus";
import AdminController from "@/controllers/AdminController";
import {router} from "@/plugins/router";

export default defineComponent({
    name: 'UploadAndRestoreBackup',
    emits: ['closeDialog'],
    setup(props, {emit}) {
        const i18n = useI18n();
        const file: Ref<File | undefined> = ref(undefined);
        const restoreBackup = async () => {
            if (file.value) {
            const response = await AdminController.restoreBackupFile(file.value)
            console.log(response.messageEN)
            if (response.status === ResponseStatus.SUCCESS) {
                emit('closeDialog');
            }
            await AdminController.logout();
            router.go(0);
        }};
        onMounted(() => {
        })

        return {
            i18n,
            file,
            restoreBackup
        }
    }
})
