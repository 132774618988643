import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.activeService.id === -1 ? _ctx.i18n.t('forms.newservice') : _ctx.i18n.t('forms.editservice')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.activeService.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeService.name) = $event)),
                label: _ctx.$t('service.fields.name')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.activeService.instance,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeService.instance) = $event)),
                label: _ctx.$t('service.fields.instance')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.activeService.secret,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeService.secret) = $event)),
                label: _ctx.$t('service.fields.secret')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.activeService.url,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.activeService.url) = $event)),
                label: _ctx.$t('service.fields.url')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.activeService.gui,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.activeService.gui) = $event)),
                label: _ctx.$t('service.fields.gui')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_checkbox, {
                modelValue: _ctx.activeService.forAllPolteq,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.activeService.forAllPolteq) = $event)),
                class: "mt-0 mb-0",
                dense: "",
                label: _ctx.$t('service.fields.forAllPolteq')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_checkbox, {
                modelValue: _ctx.activeService.forAllLoggedIn,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.activeService.forAllLoggedIn) = $event)),
                class: "mt-0 mb-0",
                dense: "",
                label: _ctx.$t('service.fields.forAllLoggedIn')
              }, null, 8, ["modelValue", "label"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            outlined: "",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('closeDialog')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('buttons.cancel')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            outlined: "",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.save()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('buttons.save')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}