import {defineComponent, onMounted, Ref, ref} from 'vue'
import {useUserStore} from '@/plugins/stores'
import {ActiveDialog} from '@/enums/ActiveDialog'
import {useI18n} from 'vue-i18n'
import AdminController from "@/controllers/AdminController";
import AddBackup from "@/components/AddBackup/AddBackup.vue";
import RestoreBackup from "@/components/RestoreBackup/RestoreBackup.vue";
import UploadAndRestoreBackup from "@/components/UploadAndRestoreBackup/UploadAndRestoreBackup.vue";
import DeleteBackup from "@/components/DeleteBackup/DeleteBackup.vue";

export default defineComponent({
    name: 'BackupPage',
    components: {
        DeleteBackup,
        UploadAndRestoreBackup,
        RestoreBackup,
        AddBackup
    },
    setup() {
        const i18n = useI18n();
        const activeDialog: Ref<ActiveDialog> = ref(ActiveDialog.NONE);
        const search = ref('');
        const selectedBackup: Ref<string> = ref('');
        const headers = () => [
            {title: i18n.t('backup.fields.name'), key: 'name'},
            {title: i18n.t('table.columns.actions'), key: 'actions', sortable: false}
        ];
        const items = ref(new Array<{ name: string }>());
        const userStore = useUserStore();
        const footerProps = {
            itemsPerPageAllText: i18n.t('pagination.all'),
            itemsPerPageText: i18n.t('pagination.perpage')
        };
        const close = () => {
            activeDialog.value = ActiveDialog.NONE;
            showDialog.value = false;
        };
        const addItem = () => {
            activeDialog.value = ActiveDialog.ADD;
            showDialog.value = true;
        };
        const restoreItem = (item: string) => {
            activeDialog.value = ActiveDialog.RESTORE;
            showDialog.value = true;
            selectedBackup.value = item;
        };
        const restoreBackupFile = () => {
            activeDialog.value = ActiveDialog.UPLOAD_AND_RESTORE;
            showDialog.value = true;
        };
        const deleteItem = (item: string) => {
            activeDialog.value = ActiveDialog.DELETE;
            showDialog.value = true;
            selectedBackup.value = item;
        };
        const updateUI = async () => {
            close();
            items.value = (await AdminController.getAllBackups()).map((item) => ({name: item}));
        };
        const showDialog = ref(false);
        onMounted(async () => {
            await updateUI();
        });
        return {
            i18n,
            close,
            addItem,
            restoreItem,
            restoreBackupFile,
            deleteItem,
            selectedBackup,
            footerProps,
            activeDialog,
            search,
            userStore,
            headers,
            items,
            updateUI,
            showDialog
        }
    },
    computed: {
        ActiveDialog() {
            return ActiveDialog
        }
    }
})


// import { Component, Watch } from 'vue-property-decorator';
// import BasePage from '@/pages/basePage';
// import AdminController from '@/controllers/AdminController';
//
// @Component
// export default class Backups extends BasePage {

//   public description = '';
//   public file: any = null;
//


//

//   public async restoreBackupFile() {
//     this.setSnackbar(await AdminController.restoreBackupFile(this.file));
//     this.file = null;
//     this.close();
//     await AdminController.logout();
//     await this.$router.go(0);
//   }
// }
