export enum Action {
  CHOOSE = 'Choose',
  APPROVE = 'Approve',
  DISABLE = 'Disable',
  DELETE = 'Delete',
  ENABLE = 'Enable',
  UNBLOCK = 'Unblock',
  LINK = 'Link',
  UNLINK = 'Unlink'
}
