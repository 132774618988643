<template>
  <v-card>
    <v-card-title>{{ i18n.t('forms.newbackup') }}</v-card-title>
    <v-card-text>
      <v-container>
        <v-text-field
          v-model="description"
          :label="$t('backup.fields.name')"
        />
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        @click="$emit('closeDialog')"
      >
        {{ $t('buttons.cancel') }}
      </v-btn>
      <v-btn
        outlined
        @click="backup()"
      >
        {{ $t('buttons.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" src="./AddBackup.ts" />
