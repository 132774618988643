import { createApp } from 'vue'
import { router } from '@/plugins/router'
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import { createPinia } from 'pinia'

import AdminPortal from '@/pages/AdminPortal/AdminPortal.vue'
import '@/pages/AdminPortal/polteq.scss'

const app = createApp(AdminPortal)
app.use(vuetify)
app.use(i18n)
app.use(router)
app.use(createPinia())

app.mount('#app')
