import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router'
import HomePage from '@/pages/HomePage/HomePage.vue'
import UsersPage from '@/pages/UsersPage/UsersPage.vue'
import ServicesPage from "@/pages/ServicesPage/ServicesPage.vue";
import BackupPage from "@/pages/BackupPage/BackupPage.vue";

export const routes: RouteRecordRaw[] = [
  {
    component: HomePage,
    name: 'home',
    path: ''
  },
  {
    component: ServicesPage,
    name: 'services',
    path: '/services'
  },
  {
    component: UsersPage,
    name: 'users',
    path: '/users'
  },
  {
    component: BackupPage,
    name: 'backups',
    path: '/backups'
  }

]

export const router: Router = createRouter({
  history: createWebHistory(),
  routes
})
