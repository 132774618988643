<template>
  <v-card>
    <v-card-title>{{ activeService.id === -1 ? i18n.t('forms.newservice') : i18n.t('forms.editservice') }}</v-card-title>
    <v-card-text>
      <v-container>
        <v-text-field
          v-model="activeService.name"
          :label="$t('service.fields.name')"
        />
        <v-text-field
          v-model="activeService.instance"
          :label="$t('service.fields.instance')"
        />
        <v-text-field
          v-model="activeService.secret"
          :label="$t('service.fields.secret')"
        />
        <v-text-field
          v-model="activeService.url"
          :label="$t('service.fields.url')"
        />
        <v-text-field
          v-model="activeService.gui"
          :label="$t('service.fields.gui')"
        />
        <v-checkbox
          v-model="activeService.forAllPolteq"
          class="mt-0 mb-0"
          dense
          :label="$t('service.fields.forAllPolteq')"
        />
        <v-checkbox
          v-model="activeService.forAllLoggedIn"
          class="mt-0 mb-0"
          dense
          :label="$t('service.fields.forAllLoggedIn')"
        />
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        @click="$emit('closeDialog')"
      >
        {{ $t('buttons.cancel') }}
      </v-btn>
      <v-btn
        outlined
        @click="save()"
      >
        {{ $t('buttons.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" src="./AddEditService.ts">
</script>
