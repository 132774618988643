import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_system_bar = _resolveComponent("v-system-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, {
    id: "v-app",
    app: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_navigation_drawer, {
            "expand-on-hover": "",
            permanent: "",
            "z-index": "200",
            theme: _ctx.userStore.mode==='light'?'dark':'light',
            rail: ""
          }, {
            append: _withCtx(() => [
              _createVNode(_component_v_list, {
                dense: "",
                nav: "",
                class: "py-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_divider),
                  (!_ctx.loggedIn)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 0,
                        href: _ctx.loginUrl(),
                        "prepend-icon": "mdi-account-circle-outline",
                        title: _ctx.i18n.t('buttons.signin')
                      }, null, 8, ["href", "title"]))
                    : _createCommentVNode("", true),
                  (_ctx.loggedIn)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 1,
                        "prepend-icon": "mdi-account-circle-outline",
                        title: _ctx.i18n.t('buttons.signout'),
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
                      }, null, 8, ["title"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list, {
                dense: "",
                nav: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item, {
                    "two-line": "",
                    "prepend-avatar": require('@/assets/white_polteqe.png'),
                    title: 'Polteq ID - ' + _ctx.i18n.t('app.title')
                  }, null, 8, ["prepend-avatar", "title"]),
                  _createVNode(_component_v_divider),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routerlinks.values(), (item) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      key: item.title,
                      link: "",
                      href: item.ref,
                      "prepend-icon": item.icon,
                      title: item.title
                    }, null, 8, ["href", "prepend-icon", "title"]))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer)
            ]),
            _: 1
          }, 8, ["theme"]),
          _createVNode(_component_v_system_bar, {
            theme: _ctx.userStore.mode==='light'?'dark':'light'
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("a", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.i18n.locale.value = 'nl'))
                }, "NL"),
                _cache[3] || (_cache[3] = _createTextVNode(" | ")),
                _createElementVNode("a", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.i18n.locale.value = 'en'))
                }, "EN"),
                _cache[4] || (_cache[4] = _createTextVNode("      "))
              ])
            ]),
            _: 1
          }, 8, ["theme"]),
          _createVNode(_component_v_main, {
            "max-height": "90vh",
            style: {"overflow-y":"auto"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}