<template>
  <v-card>
    <v-card-title>{{ $t('forms.linkservice') }}</v-card-title>
    <v-card-subtitle v-if="activeDialog === ActiveDialog.LINK">
      {{ activeUser.firstname }}
      {{ activeUser.lastname }} ({{ activeUser.email }})
    </v-card-subtitle>
    <v-card-subtitle v-if="activeDialog === ActiveDialog.GROUPLINK">
      {{ selected.length }}
      {{ $t('text.multiuser') }}
    </v-card-subtitle>
    <v-card-subtitle v-if="activeDialog === ActiveDialog.GROUPLINK && link">
      {{
        $t('text.link')
      }}
    </v-card-subtitle>
    <v-card-subtitle v-else-if="activeDialog === ActiveDialog.GROUPLINK && !link">
      {{
        $t('text.unlink')
      }}
    </v-card-subtitle>
    <v-card-text>
      <v-data-table
        :headers="serviceHeaders()"
        :items="services"
        :search="search"
        :items-per-page="10"
        item-key="id"
        density="compact"
        class="elevation-2"
        :items-per-page-text="i18n.t('pagination.perpage')"
        :items-per-page-options="[{title: '10', value: 10}, {title: '20', value: 20} , {title: i18n.t('pagination.all'), value: -1}]"
        :page-text="'{0} - {1} ' + i18n.t('pagination.of') + ' {2}'"
      >
        <template #top>
          <v-toolbar flat>
            <v-toolbar-title>
              {{ $t('pages.services') }}
            </v-toolbar-title>
            <v-spacer />
            <v-text-field
              :v-model="search"
              append-icon="mdi-magnify"
              :label="$t('fields.search')"
              single-line
              hide-details
            />
          </v-toolbar>
        </template>
        <template #item.link="{ item }">
          <v-checkbox
            v-model="item.link"
            density="compact"
          />
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        @click="$emit('closeDialog')"
      >
        {{ $t('buttons.cancel') }}
      </v-btn>
      <v-btn
        v-if="activeDialog === ActiveDialog.LINK"
        outlined
        @click="linkUser()"
      >
        {{
          $t('buttons.link')
        }}
      </v-btn>
      <v-btn
        v-if="activeDialog === ActiveDialog.GROUPLINK"
        outlined
        @click="linkUsers()"
      >
        {{
          $t('buttons.link')
        }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" src="./LinkGroupLinkUser.ts" />

