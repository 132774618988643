import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.activeUser.id === -1 ? _ctx.i18n.t('forms.newuser') : _ctx.i18n.t('forms.edituser')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.activeUser.firstname,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeUser.firstname) = $event)),
                label: _ctx.$t('user.fields.firstname')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.activeUser.lastname,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeUser.lastname) = $event)),
                label: _ctx.$t('user.fields.lastname')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.activeUser.company,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeUser.company) = $event)),
                label: _ctx.$t('user.fields.company')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.activeUser.email,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.activeUser.email) = $event)),
                label: _ctx.$t('user.fields.email')
              }, null, 8, ["modelValue", "label"]),
              (_ctx.activeUser.id !== -1)
                ? (_openBlock(), _createBlock(_component_v_text_field, {
                    key: 0,
                    modelValue: _ctx.activeUser.email,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.activeUser.email) = $event)),
                    label: _ctx.$t('user.fields.email'),
                    readonly: ""
                  }, null, 8, ["modelValue", "label"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.activeUser.phonenumber,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.activeUser.phonenumber) = $event)),
                label: _ctx.$t('user.fields.phonenumber')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.activeUser.password,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.activeUser.password) = $event)),
                label: _ctx.$t('user.fields.password')
              }, null, 8, ["modelValue", "label"]),
              (_ctx.activeUser.id !== -1)
                ? (_openBlock(), _createBlock(_component_v_text_field, {
                    key: 1,
                    modelValue: _ctx.activeUser.password,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.activeUser.password) = $event)),
                    label: _ctx.$t('user.fields.password')
                  }, null, 8, ["modelValue", "label"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_checkbox, {
                modelValue: _ctx.activeUser.admin,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.activeUser.admin) = $event)),
                class: "mt-0 mb-0",
                dense: "",
                label: _ctx.$t('user.fields.admin')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_checkbox, {
                modelValue: _ctx.activeUser.approved,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.activeUser.approved) = $event)),
                class: "mt-0 mb-0",
                dense: "",
                label: _ctx.$t('user.fields.approved')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_checkbox, {
                modelValue: _ctx.activeUser.disabled,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.activeUser.disabled) = $event)),
                class: "mt-0 mb-0",
                dense: "",
                label: _ctx.$t('user.fields.disabled')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_checkbox, {
                modelValue: _ctx.activeUser.locked,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.activeUser.locked) = $event)),
                class: "mt-0 mb-0",
                dense: "",
                label: _ctx.$t('user.fields.locked')
              }, null, 8, ["modelValue", "label"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            outlined: "",
            onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('closeDialog')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('buttons.cancel')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            outlined: "",
            onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.save()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('buttons.save')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}