import {defineComponent, onMounted, Ref, ref} from 'vue'
import {useI18n} from "vue-i18n";
import {ResponseStatus} from "@/enums/ResponseStatus";
import AdminController from "@/controllers/AdminController";
import {router} from "@/plugins/router";

export default defineComponent({
    name: 'RestoreBackup',
    props: {restoreString: String},
    emits: ['closeDialog'],
    setup(props, {emit}) {
        const i18n = useI18n();
        const backupName: Ref<string> = ref('');
        const restoreBackup = async () => {


            const response = await AdminController.restoreBackup(backupName.value)
            console.log(response.messageEN)
            if (response.status === ResponseStatus.SUCCESS) {
                emit('closeDialog');
            }
            await AdminController.logout();
            router.go(0);
        }
        onMounted(() => {
            backupName.value = props.restoreString as string;
        })

        return {
            i18n,
            backupName,
            restoreBackup
        }
    }
})
