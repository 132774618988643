<template>
  <v-card
    id="idSessionsCard"
    width="25vw"
    min-height="75px"
    height="20vh"
    :elevation="2"
  >
    <v-card-title>ID sessions:</v-card-title>
    <v-card-text>{{ sessions.length }}</v-card-text>
  </v-card>
</template>

<script lang="ts" src="./IDSessions.ts" />
