import {defineComponent, onMounted, ref} from 'vue'
import {useI18n} from "vue-i18n";
import {ResponseStatus} from "@/enums/ResponseStatus";
import Service from "@/models/Service";
import ServiceController from "@/controllers/ServiceController";

export default defineComponent({
    name: 'DeleteService',
    props: {service: Service},
    emits: ['closeDialog'],
    setup(props, {emit}) {
        const i18n = useI18n();
        const activeService = ref(new Service());
        const deleteService = async () => {
            const response = await ServiceController.delete(activeService.value.id);
            console.log(response.messageEN)
            if (response.status === ResponseStatus.SUCCESS) {
                emit('closeDialog');
            }
        };
        onMounted(() => {
            activeService.value = props.service as Service;
        })

        return {
            i18n,
            activeService,
            deleteService
        }
    }
})
