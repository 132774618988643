import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_IDSessions = _resolveComponent("IDSessions")!
  const _component_LastNewUser = _resolveComponent("LastNewUser")!
  const _component_SessionsPerService = _resolveComponent("SessionsPerService")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { "no-gutters": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { style: {"text-align":"center"} }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("h1", null, "Polteq ID - Dashboard", -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.userStore.userInfo.email !== '')
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 0,
            "no-gutters": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_IDSessions)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_LastNewUser)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_SessionsPerService)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}