import {defineComponent, onMounted, ref} from 'vue'
import User from "@/models/User";
import {useI18n} from "vue-i18n";
import UserController from "@/controllers/UserController";
import {ResponseStatus} from "@/enums/ResponseStatus";

export default defineComponent({
    name: 'DeleteUser',
    props: {user: User},
    emits: ['closeDialog'],
    setup(props, {emit}) {
        const i18n = useI18n();
        const activeUser = ref(new User());
        const deleteUser = async () => {
            const response = await UserController.delete(activeUser.value.id);
            console.log(response.messageEN)
            if (response.status === ResponseStatus.SUCCESS) {
                emit('closeDialog');
            }
        };
        onMounted(() => {
            activeUser.value = props.user as User;
        })

        return {
            i18n,
            activeUser,
            deleteUser
        }
    }
})
