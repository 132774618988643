<template>
  <v-card>
    <v-card-title>{{ activeUser.id === -1 ? i18n.t('forms.newuser') : i18n.t('forms.edituser') }}</v-card-title>
    <v-card-text>
      <v-container>
        <!--                   <ValidationProvider-->
        <!--                      v-slot="{ errors }"-->
        <!--                      name="user.fields.firstname"-->
        <!--                      rules="required|extended_alphanumeric"-->
        <!--                    >-->
        <v-text-field
          v-model="activeUser.firstname"
          :label="$t('user.fields.firstname')"
        />
        <!--                    </ValidationProvider>-->
        <!--                    <ValidationProvider-->
        <!--                      v-slot="{ errors }"-->
        <!--                      name="user.fields.lastname"-->
        <!--                      rules="required|extended_alphanumeric"-->
        <!--                    >-->
        <v-text-field
          v-model="activeUser.lastname"
          :label="$t('user.fields.lastname')"
        />
        <!--                    </ValidationProvider>-->
        <v-text-field
          v-model="activeUser.company"
          :label="$t('user.fields.company')"
        />
        <!--                    <ValidationProvider-->
        <!--                      v-if="activeDialog === ActiveDialog.ADD"-->
        <!--                      v-slot="{ errors }"-->
        <!--                      name="user.fields.email"-->
        <!--                      rules="required|email"-->
        <!--                    >-->
        <v-text-field
          v-model="activeUser.email"
          :label="$t('user.fields.email')"
        />
        <!--                    </ValidationProvider>-->
        <v-text-field
          v-if="activeUser.id !== -1"
          v-model="activeUser.email"
          :label="$t('user.fields.email')"
          readonly
        />
        <!--                    <ValidationProvider-->
        <!--                      v-slot="{ errors }"-->
        <!--                      name="user.fields.phonenumber"-->
        <!--                      rules="phone_number"-->
        <!--                    >-->
        <v-text-field
          v-model="activeUser.phonenumber"
          :label="$t('user.fields.phonenumber')"
        />
        <!--                    </ValidationProvider>-->
        <!--                    <ValidationProvider-->
        <!--                      v-if="activeDialog === ActiveDialog.ADD"-->
        <!--                      v-slot="{ errors }"-->
        <!--                      name="user.fields.password"-->
        <!--                      rules="required|min:10"-->
        <!--                    >-->
        <v-text-field
          v-model="activeUser.password"
          :label="$t('user.fields.password')"
        />
        <!--                    </ValidationProvider>-->
        <v-text-field
          v-if="activeUser.id !== -1"
          v-model="activeUser.password"
          :label="$t('user.fields.password')"
        />
        <v-checkbox
          v-model="activeUser.admin"
          class="mt-0 mb-0"
          dense
          :label="$t('user.fields.admin')"
        />
        <v-checkbox
          v-model="activeUser.approved"
          class="mt-0 mb-0"
          dense
          :label="$t('user.fields.approved')"
        />
        <v-checkbox
          v-model="activeUser.disabled"
          class="mt-0 mb-0"
          dense
          :label="$t('user.fields.disabled')"
        />
        <v-checkbox
          v-model="activeUser.locked"
          class="mt-0 mb-0"
          dense
          :label="$t('user.fields.locked')"
        />
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        @click="$emit('closeDialog')"
      >
        {{ $t('buttons.cancel') }}
      </v-btn>
      <v-btn
        outlined
        @click="save()"
      >
        {{ $t('buttons.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" src="./AddEditUser.ts">
</script>
