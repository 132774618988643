import {defineComponent, onMounted, ref} from 'vue'
import User from "@/models/User";
import {useI18n} from "vue-i18n";
import Response from "@/models/Response";
import UserController from "@/controllers/UserController";
import {ResponseStatus} from "@/enums/ResponseStatus";
import {ActiveDialog} from "@/enums/ActiveDialog";
import SelectableService from "@/models/SelectableService";
import Service from "@/models/Service";
import ServiceController from "@/controllers/ServiceController";
import UserService from "@/models/UserService";

export default defineComponent({
    name: 'LinkGroupLinkUser',
    props: {
        user: {
            type: Object as () => User,
            default: () => ({})
        },
        users: {type: Array, default: () => new Array<User>()},
        activeDialog: {type: String, default: ActiveDialog.NONE}
    },
    emits: ['closeDialog'],
    setup(props, {emit}) {
        const i18n = useI18n();
        const activeUser = ref(new User());
        const selected = ref(new Array<User>());
        const link: boolean = true;
        const search = ref('');
        const services = ref(new Array<SelectableService>());
        const serviceHeaders = () => [
            {text: i18n.t('service.fields.name'), value: 'name'},
            {text: i18n.t('service.fields.instance'), value: 'instance'},
            {text: i18n.t('table.columns.link'), value: 'link'}
        ]
        const linkUser = async () => {
            let response: Response;
            if (activeUser.value.id > -1) {
                response = await UserController.link(activeUser.value.id, services.value)
                console.log(response.messageEN)
            } else {
                response = await UserController.create(activeUser.value)
                console.log(response.messageEN)
            }

            if (response.status === ResponseStatus.SUCCESS) {
                emit('closeDialog');
            }
        }
        const getServices = async () => {
            const polteqServices: Service[] = await ServiceController.getAllAvailable();
            const linkedServices: UserService[] = await UserController.getLinkedServices(activeUser.value.id);
            services.value = [];
            for (const polteqService of polteqServices) {
                let isSet = false;
                for (const linkedService of linkedServices) {
                    if (linkedService.service === polteqService.id) {
                        services.value.push(new SelectableService(polteqService.id, polteqService.name, polteqService.instance, true));
                        isSet = true;
                    }
                }
                if (!isSet) {
                    services.value.push(new SelectableService(polteqService.id, polteqService.name, polteqService.instance, false));
                }
            }
        }
        onMounted(async () => {
            activeUser.value = props.user;
            selected.value = props.users as User[];
            await getServices();
        })

        return {
            i18n,
            activeUser,
            selected,
            link,
            search,
            serviceHeaders,
            services,
            linkUser
        }
    },
    computed: {
        ActiveDialog() {
            return ActiveDialog
        }
    }
})
