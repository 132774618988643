import { defineComponent } from 'vue'
import IDSessions from '@/components/idsessions/IDSessions.vue'
import LastNewUser from '@/components/lastnewuser/LastNewUser.vue'
import SessionsPerService from '@/components/sessionsperservice/SessionsPerService.vue'
import { useUserStore } from '@/plugins/stores'

export default defineComponent({
  name: 'HomePage',
  components: {
    IDSessions,
    LastNewUser,
    SessionsPerService
  },
  setup () {
    const userStore = useUserStore()
    return {
      userStore
    }
  }
})
