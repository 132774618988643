export default class RouterLink {
  public title: string = ''
  public icon: string = ''
  public ref: string = ''

  constructor (title: string, icon: string, ref: string) {
    this.title = title
    this.icon = icon
    this.ref = ref
  }
}
