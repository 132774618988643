<template>
  <v-data-table
    v-model="selected"
    :headers="headers()"
    :items="items"
    :search="search"
    :items-per-page="10"
    item-key="id"
    class="elevation-2"
    show-select
    :sort-by="[{ key: 'lastname', order: 'asc' }, { key: 'firstname', order: 'asc' }]"
    :items-per-page-text="i18n.t('pagination.perpage')"
    :items-per-page-options="[{title: '10', value: 10}, {title: '20', value: 20} , {title: i18n.t('pagination.all'), value: -1}]"
    :page-text="'{0} - {1} ' + i18n.t('pagination.of') + ' {2}'"
  >
    <template #top>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ i18n.t('pages.users') }}
        </v-toolbar-title>
        <v-spacer />
        <template v-if="selected.length > 0">
          <v-select
            v-model="action"
            :label="i18n.t('fields.bulkaction')"
            :items="actions()"
            single-line
            hide-details
            clearable
          />
          <v-btn
            class="ml-4"
            variant="elevated"
            :theme="userStore.mode==='light'?'dark':'light'"
            left
            @click="executeBulkAction()"
          >
            {{ $t('buttons.bulkaction') }}
          </v-btn>
        </template>
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="i18n.t('fields.search')"
          single-line
          hide-details
        />
        <v-tooltip :text="i18n.t('buttons.addUser')">
          <template #activator="{ props }">
            <v-fab
              v-bind="props"
              absolute
              location="end"
              variant="elevated"
              style="max-width: 64px; margin-right: 8px"
              :theme="userStore.mode==='light'?'dark':'light'"
              icon="mdi-plus"
              @click="addItem()"
            />
          </template>
        </v-tooltip>
        <v-dialog
          v-if="activeDialog !== ActiveDialog.NONE"
          v-model="showDialog"
          max-width="500px"
        >
          <AddEditUser
            v-if="activeDialog === ActiveDialog.ADD || activeDialog === ActiveDialog.EDIT"
            :user="editedItem"
            @close-dialog="activeDialog = ActiveDialog.NONE; updateUI()"
          />
          <DeleteUser
            v-if="activeDialog === ActiveDialog.DELETE"
            :user="editedItem"
            @close-dialog="activeDialog = ActiveDialog.NONE; updateUI()"
          />
          <LinkGroupLinkUser
            v-if="activeDialog === ActiveDialog.LINK || activeDialog === ActiveDialog.GROUPLINK"
            :user="editedItem"
            :users="selected"
            :active-dialog="activeDialog"
            @close-dialog="activeDialog = ActiveDialog.NONE; updateUI()"
          />
        </v-dialog>
      </v-toolbar>
    </template>
    <template #item.password>
      {{ '*******' }}
    </template>
    <template #item.admin="{ item }">
      <v-icon>
        {{ item.admin ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
      </v-icon>
    </template>
    <template #item.approved="{ item }">
      <v-icon>
        {{ item.approved ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
      </v-icon>
    </template>
    <template #item.disabled="{ item }">
      <v-icon>
        {{ item.disabled ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
      </v-icon>
    </template>
    <template #item.locked="{ item }">
      <v-icon>
        {{ item.locked ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
      </v-icon>
    </template>
    <template #item.lastlogin="{ item }">
      {{
        item.lastlogin === -1 ? '-' : new Date(item.lastlogin).toLocaleDateString('nl-NL') + ' ' + new Date(item.lastlogin).toLocaleTimeString('nl-NL')
      }}
    </template>
    <template #item.actions="{ item }">
      <v-icon
        color="info"
        small
        class="mr-2"
        @click="linkItem(item)"
      >
        mdi-link-variant
      </v-icon>
      <v-icon
        color="success"
        small
        class="mr-2"
        @click="editedItem = item; activeDialog = ActiveDialog.EDIT"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        color="error"
        small
        @click="editedItem = item; activeDialog = ActiveDialog.DELETE"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script lang="ts" src="./UsersPage.ts"></script>
