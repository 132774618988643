import axios, { AxiosError } from 'axios'
import User from '@/models/User'
import SelectableService from '@/models/SelectableService'
import UserService from '@/models/UserService'
import Settings from '@/config/Settings'
import Response from '../models/Response'
import AxiosHelper from '@/util/AxiosHelper'

axios.defaults.withCredentials = true
axios.defaults.headers.common = { 'Content-Type': 'application/json' }

export default class UserController {
  private static clientId: string = '?clientId=ADMIN_PORTAL'

  public static async getAll (): Promise<User[]> {
    return await axios
      .get(`${Settings.usersUrl}${this.clientId}`)
      .then((response) => {
        if (response.status === 200) {
          return response.data as User[]
        } else {
          return []
        }
      })
      .catch(() => {
        return []
      })
  }

  public static async create (user: User): Promise<Response> {
    return axios.post(`${Settings.usersUrl}${this.clientId}`, user)
      .then((response): Response => {
        if (response.status === 201) {
          return Response.buildSuccesResponse(
                        `User ${user.email} created successfully`,
                        `Gebruiker ${user.email} succesvol aangemaakt`)
        } else {
          return Response.buildFailedResponse(
            response.status,
                        `Expected status 201 but got ${response.status}`,
                        `Verwachte status is 201, maar ontvangen status is ${response.status}`
          )
        }
      })
      .catch((error: AxiosError) => {
        return AxiosHelper.handleErrorResponse(error)
      })
  }

  public static async update (user: User): Promise<Response> {
    return await axios.put(`${Settings.usersUrl}/${user.id}${this.clientId}`, user)
      .then((response) => {
        if (response.status === 200) {
          return Response.buildSuccesResponse(
                        `User ${user.email} updated successfully`,
                        `Gebruiker ${user.email} succesvol aangepast`)
        } else {
          return Response.buildFailedResponse(
            response.status,
                        `Expected status 200 but got ${response.status}`,
                        `Verwachte status is 200, maar ontvangen status is ${response.status}`
          )
        }
      }).catch((error: AxiosError) => {
        return AxiosHelper.handleErrorResponse(error)
      })
    // .catch((error) => {
    //   return Response.buildFailedResponse(
    //     error.status,
    //     `Failed to update user ${user.email}`,
    //     `Gebruiker ${user.email} aanpassen is mislukt`);
    // });
  }

  public static async delete (id: number): Promise<Response> {
    return await axios.delete(`${Settings.usersUrl}/${id}${this.clientId}`)
      .then((response) => {
        if (response.status === 204) {
          return Response.buildSuccesResponse(
                        `User with id  ${id} deleted successfully`,
                        `Gebruiker met id  ${id} succesvol verwijderd`)
        } else {
          return Response.buildFailedResponse(
            response.status,
                        `Expected status 204 but got ${response.status}`,
                        `Verwachte status is 204, maar ontvangen status is ${response.status}`
          )
        }
      })
      .catch((error: AxiosError) => {
        return AxiosHelper.handleErrorResponse(error)
      })
    // .catch((error) => {
    //   return Response.buildFailedResponse(
    //     error.status,
    //     `Failed to delete user with id ${id}`,
    //     `Gebruiker met id  ${id} verwijderen is mislukt`);
    // });
  }

  public static async link (id: number, services: SelectableService[]): Promise<Response> {
    const serviceIds = new Array<number>()
    let serviceMessage = ''
    for (const service of services) {
      if (service.link) {
        serviceIds.push(service.id)
        serviceMessage += `${service.name}  ${service.instance}, `
      }
    }
    serviceMessage = serviceMessage.substr(0, serviceMessage.lastIndexOf(','))
    return await axios.post(`${Settings.usersUrl}/${id}${this.clientId}`, serviceIds)
      .then((response) => {
        if (response.status === 200) {
          return Response.buildSuccesResponse(
                        `User with id ${id} linked to services: ${serviceMessage}`,
                        `Gebruiker met id ${id} is gelinked aan: ${serviceMessage}`)
        } else {
          return Response.buildFailedResponse(
            response.status,
                        `Expected status 200 but got ${response.status}`,
                        `Verwachte status is 200, maar ontvangen status is ${response.status}`
          )
        }
      })
      .catch((error: AxiosError) => {
        return AxiosHelper.handleErrorResponse(error)
      })
    // .catch((error) => {
    //   return Response.buildFailedResponse(
    //     error.status,
    //     `Failed to link user with id ${id} to services: ${serviceMessage}`,
    //     `Linken van gebruiker met id ${id} mislukt voor : ${serviceMessage}`);
    // });
  }

  public static async getLinkedServices (id: number): Promise<UserService[]> {
    const response = await axios.get(`${Settings.usersUrl}/${id}/linkedservices${this.clientId}`)
    return response.data as UserService[]
  }
}
