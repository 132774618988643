import {defineComponent, onBeforeMount, onMounted, Ref, ref, watch} from 'vue'
import {useUserStore} from '@/plugins/stores'
import {Action} from '@/enums/Action'
import {ActiveDialog} from '@/enums/ActiveDialog'
import User from '@/models/User'
import SelectableService from '@/models/SelectableService'
import UserController from '@/controllers/UserController'
import {useI18n} from 'vue-i18n'
import AddEditUser from "@/components/AddEditUser/AddEditUser.vue";
import DeleteUser from "@/components/DeleteUser/DeleteUser.vue";
import LinkGroupLinkUser from "@/components/LinkGroupLinkUser/LinkGroupLinkUser.vue";

export default defineComponent({
    name: 'UsersPage',
    components: {
        LinkGroupLinkUser,
        DeleteUser,
        AddEditUser
    },
    setup() {
        const i18n = useI18n();
        const activeDialog: Ref<ActiveDialog> = ref(ActiveDialog.NONE);
        const search = ref('');
        const action = ref(Action.CHOOSE);
        const headers = () => [
            {title: i18n.t('user.fields.firstname'), key: 'firstname'},
            {title: i18n.t('user.fields.lastname'), key: 'lastname'},
            {title: i18n.t('user.fields.company'), key: 'company'},
            {title: i18n.t('user.fields.email'), key: 'email'},
            {title: i18n.t('user.fields.phonenumber'), key: 'phonenumber'},
            {title: i18n.t('user.fields.password'), key: 'password'},
            {title: i18n.t('user.fields.admin'), key: 'admin'},
            {title: i18n.t('user.fields.approved'), key: 'approved'},
            {title: i18n.t('user.fields.disabled'), key: 'disabled'},
            {title: i18n.t('user.fields.locked'), key: 'locked'},
            {title: i18n.t('user.fields.lastlogin'), key: 'lastlogin'},
            {title: i18n.t('table.columns.actions'), key: 'actions', sortable: false}
        ];
        const actions = () => [
            {title: i18n.t('action.approve'), value: Action.APPROVE},
            {title: i18n.t('action.link'), value: Action.LINK},
            {title: i18n.t('action.unlink'), value: Action.UNLINK},
            {title: i18n.t('action.enable'), value: Action.ENABLE},
            {title: i18n.t('action.disable'), value: Action.DISABLE},
            {title: i18n.t('action.unblock'), value: Action.UNBLOCK},
            {title: i18n.t('action.delete'), value: Action.DELETE}
        ];
        const items = ref(new Array<User>());
        const userStore = useUserStore();
        const editedItem = ref(new User());
        const selected = ref(new Array<User>());
        const serviceMap: Map<number, SelectableService> = new Map<number, SelectableService>();
        const services: SelectableService[] = new Array<SelectableService>();
        const link: boolean = true;
        const footerProps = {
            itemsPerPageAllText: i18n.t('pagination.all'),
            itemsPerPageText: i18n.t('pagination.perpage')
        };
        const formTitle = () => {
            return editedItem.value.id === -1 ? i18n.t('forms.newuser') : i18n.t('forms.edituser')
        };
        const close = () => {
            activeDialog.value = ActiveDialog.NONE
        };
        const addItem = () => {
            editedItem.value = new User()
            activeDialog.value = ActiveDialog.ADD
        };
        const linkItem = (item: object) => {
            editedItem.value = item as User;
            activeDialog.value = ActiveDialog.LINK;
        }
        const updateUI = async () => {
          items.value = await UserController.getAll();
          editedItem.value = new User();
        };
        const showDialog =  ref(false);
        onMounted(async () => {
            await updateUI();
        });
        onBeforeMount(() => {
            watch(
                () => activeDialog.value,
                 () => {
                   showDialog.value = activeDialog.value !== ActiveDialog.NONE;
                }
            );
        });
        return {
            i18n,
            close,
            formTitle,
            addItem,
            linkItem,
            footerProps,
            activeDialog,
            action,
            actions,
            search,
            selected,
            userStore,
            headers,
            serviceMap,
            items,
            editedItem,
            link,
            services,
            updateUI,
            showDialog
        }
    },
    computed: {
        User() {
            return User
        },
        ActiveDialog() {
            return ActiveDialog
        }
    }
})

//
//   public async linkItem(item: User) {
//     const polteqServices: Service[] = await ServiceController.getAllAvailable();
//     const linkedServices: UserService[] = await UserController.getLinkedServices(item.id);
//     for (const polteqService of polteqServices) {
//       let isSet = false;
//       for (const linkedService of linkedServices) {
//         if (linkedService.service === polteqService.id) {
//           this.services.push(new SelectableService(polteqService.id, polteqService.name, polteqService.instance, true));
//           isSet = true;
//         }
//       }
//       if (!isSet) {
//         this.services.push(new SelectableService(polteqService.id, polteqService.name, polteqService.instance, false));
//       }
//     }
//     this.editedItem = item;
//     this.activeDialog = ActiveDialog.LINK;
//   }
//
//   public addItem() {
//     this.editedItem = new User();
//     this.activeDialog = ActiveDialog.ADD;
//   }
//
//   public editItem(item: User) {
//     this.editedItem = item;
//     this.activeDialog = ActiveDialog.EDIT;
//   }
//
//   public deleteItem(item: User) {
//     this.editedItem = item;
//     this.activeDialog = ActiveDialog.DELETE;
//   }
//
//   @Watch('activeDialog')
//   public async reset() {
//     if (!this.activeDialog || this.activeDialog === ActiveDialog.NONE) {
//       this.items = await UserController.getAll();
//       this.editedItem = new User();
//       this.services = [];
//       this.serviceMap.clear();
//       this.action = Action.CHOOSE;
//     }
//     if (this.activeDialog === ActiveDialog.GROUPLINK) {
//       this.services = [];
//       this.serviceMap.clear();
//       const polteqServices: Service[] = await ServiceController.getAllAvailable();
//       for (const service of polteqServices) {
//         const selectableService = new SelectableService(service.id, service.name, service.instance, false);
//         this.services.push(selectableService);
//         this.serviceMap.set(service.id, selectableService);
//       }
//     }
//   }
//
//   public close() {
//     this.activeDialog = ActiveDialog.NONE;
//   }
//

//
//   public async linkUsers() {
//     const messages = new Array<Response>();
//     for (const user of this.selected) {
//       const linkedServices: UserService[] = await UserController.getLinkedServices(user.id);
//       let linkedSelectableServices: SelectableService[] = new Array<SelectableService>();
//       for (const userService of linkedServices) {
//         if (this.serviceMap.has(userService.service)) {
//           const currentUserService = this.serviceMap.get(userService.service);
//           if (currentUserService !== undefined) {
//             const linkedService: SelectableService = Object.assign({}, currentUserService);
//             linkedService.link = true;
//             linkedSelectableServices.push(linkedService);
//           }
//         }
//       }
//       linkedSelectableServices = this.link ?
//         await this.linkNewServices(linkedSelectableServices) :
//         await this.unlinkNewServices(linkedSelectableServices);
//       messages.push(await UserController.link(user.id, linkedSelectableServices));
//     }
//     this.setSnackbarMultiple(messages);
//     this.close();
//   }
//


//
//   public async executeBulkAction() {
//     const messages = new Array<Response>();
//     for (const user of this.selected) {
//       switch (this.action) {
//         case Action.APPROVE:
//           user.approved = true;
//           messages.push(await UserController.update(user));
//           break;
//         case Action.DISABLE:
//           user.disabled = true;
//           messages.push(await UserController.update(user));
//           break;
//         case Action.ENABLE:
//           user.disabled = false;
//           messages.push(await UserController.update(user));
//           break;
//         case Action.DELETE:
//           messages.push(await UserController.delete(user.id));
//           break;
//         case Action.UNBLOCK:
//           user.locked = false;
//           messages.push(await UserController.update(user));
//           break;
//         case Action.LINK:
//           this.link = true;
//           this.activeDialog = ActiveDialog.GROUPLINK;
//           break;
//         case Action.UNLINK:
//           this.link = false;
//           this.activeDialog = ActiveDialog.GROUPLINK;
//           break;
//         default:
//           break;
//       }
//     }
//     if (this.action !== Action.LINK && this.action !== Action.UNLINK) {
//       this.setSnackbarMultiple(messages);
//       await this.reset();
//     }
//   }
//
//   private async linkNewServices(linkedSelectableServices: SelectableService[]): Promise<SelectableService[]> {
//     for (const toLink of this.services) {
//       let found = false;
//       if (toLink.link) {
//         for (const linkedSelectableService of linkedSelectableServices) {
//           if (toLink.id === linkedSelectableService.id) {
//             found = true;
//             break;
//           }
//         }
//         if (!found) {
//           linkedSelectableServices.push(toLink);
//         }
//       }
//     }
//     return linkedSelectableServices;
//   }
//
//   private async unlinkNewServices(linkedSelectableServices: SelectableService[]): Promise<SelectableService[]> {
//     const newServices = [];
//     for (const linkedSelectableService of linkedSelectableServices) {
//       for (const toUnlink of this.services) {
//         if (toUnlink.link && toUnlink.id === linkedSelectableService.id) {
//           linkedSelectableService.link = false;
//           break;
//         }
//       }
//       newServices.push(linkedSelectableService);
//     }
//     return newServices;
//   }
// }
