<template>
  <v-app
    id="v-app"
    app
  >
    <v-layout>
      <v-navigation-drawer
        expand-on-hover
        permanent
        z-index="200"
        :theme="userStore.mode==='light'?'dark':'light'"
        rail
      >
        <v-list
          dense
          nav
        >
          <v-list-item
            two-line
            :prepend-avatar="require('@/assets/white_polteqe.png')"
            :title="'Polteq ID - ' + i18n.t('app.title')"
          />
          <v-divider />

          <v-list-item
            v-for="item in routerlinks.values()"
            :key="item.title"
            link
            :href="item.ref"
            :prepend-icon="item.icon"
            :title="item.title"
          />
        </v-list>
        <v-spacer />
        <template #append>
          <v-list
            dense
            nav
            class="py-0"
          >
            <v-divider />
            <v-list-item
              v-if="!loggedIn"
              :href="loginUrl()"
              prepend-icon="mdi-account-circle-outline"
              :title="i18n.t('buttons.signin')"
            />
            <!--          <v-list-group-->
            <!--              v-else-->
            <!--              prepend-icon="mdi-account-circle-outline"-->
            <!--              :value="false"-->
            <!--              no-action-->
            <!--          >-->
            <!--            <template #activator>-->
            <!--              <v-list-item-title id="username-list-item">-->
            <!--                {{ $root.$data.userName }}-->
            <!--              </v-list-item-title>-->
            <!--            </template>-->
            <!--            <v-list-item href="/usersettings">-->
            <!--              <v-list-item-content>User settings</v-list-item-content>-->
            <!--            </v-list-item>-->
            <v-list-item
              v-if="loggedIn"
              prepend-icon="mdi-account-circle-outline"
              :title="i18n.t('buttons.signout')"
              @click="logout()"
            />
            <!--            <v-list-item @click="logout()">-->
            <!--              <v-list-item-content>Log out</v-list-item-content>-->
            <!--            </v-list-item>-->
            <!--          </v-list-group>-->
          </v-list>
        </template>
      </v-navigation-drawer>
      <v-system-bar :theme="userStore.mode==='light'?'dark':'light'">
        <div>
          <a @click="i18n.locale.value = 'nl'">NL</a> | <a
            @click="i18n.locale.value = 'en'"
          >EN</a>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </v-system-bar>
      <v-main
        max-height="90vh"
        style="overflow-y: auto"
      >
        <router-view />
      </v-main>
      <!--        &lt;!&ndash;      <v-snackbar&ndash;&gt;-->
      <!--        &lt;!&ndash;        v-model="$root.$data.showSnackbar"&ndash;&gt;-->
      <!--        &lt;!&ndash;        :multi-line="true"&ndash;&gt;-->
      <!--        &lt;!&ndash;      >&ndash;&gt;-->
      <!--        &lt;!&ndash;        <v-content&ndash;&gt;-->
      <!--        &lt;!&ndash;          id="snackbarcontents"&ndash;&gt;-->
      <!--        &lt;!&ndash;          v-html="$root.$data.snackbarContents"&ndash;&gt;-->
      <!--        &lt;!&ndash;        />&ndash;&gt;-->
      <!--        &lt;!&ndash;        <v-btn&ndash;&gt;-->
      <!--        &lt;!&ndash;          dark&ndash;&gt;-->
      <!--        &lt;!&ndash;          @click="$root.showSnackbar = false"&ndash;&gt;-->
      <!--        &lt;!&ndash;        >&ndash;&gt;-->
      <!--        &lt;!&ndash;          close&ndash;&gt;-->
      <!--        &lt;!&ndash;        </v-btn>&ndash;&gt;-->
      <!--        &lt;!&ndash;      </v-snackbar>&ndash;&gt;-->
    </v-layout>
  </v-app>
</template>

<script lang="ts" src="./AdminPortal.ts" />
<style lang="scss" src="./polteq.scss" />
