import axios, {AxiosError, isAxiosError} from 'axios'
import Settings from '@/config/Settings'
import UserInfoResponse from '@/models/UserInfoResponse'
import {JsonConvert} from 'json2typescript'
import UserInfo from '@/models/UserInfo'
import Response from '@/models/Response'
import AxiosHelper from "@/util/AxiosHelper";

axios.defaults.withCredentials = true

export default class AdminController {
    private static clientId: string = '?clientId=ADMIN_PORTAL'

    public static async logout() {
        await axios.get(`${Settings.adminBackend}/logout`)
    }

    public static async getUserInfo(): Promise<UserInfoResponse> {
        return await axios
            .get(`${Settings.adminBackend}/userinfo`)
            .then((response) => {
                if (response.status === 200) {
                    return UserInfoResponse.buildSuccesResponse(new JsonConvert().deserializeObject(response.data, UserInfo))
                } else {
                    return UserInfoResponse.buildFailedResponse(response.status)
                }
            })
            .catch((error: Error | AxiosError) => {
                if (isAxiosError(error) && error.response) {
                    return UserInfoResponse.buildFailedResponse(error.response.status)
                }
                return UserInfoResponse.buildFailedResponse(418)
            })
    }

    public static async getSSOSessions(): Promise<string[]> {
        return await axios
            .get(`${Settings.adminBackend}/ssosessions`)
            .then((response) => {
                if (response.status === 200) {
                    return response.data as string[]
                } else {
                    return []
                }
            })
            .catch(() => {
                return []
            })
    }

    public static async getLastNewUser(): Promise<string> {
        return await axios
            .get(`${Settings.adminBackend}/lastnewuser`)
            .then((response) => {
                if (response.status === 200) {
                    return response.data as string
                } else {
                    return ''
                }
            })
            .catch(() => {
                return ''
            })
    }

    public static async getSessionsPerService(): Promise<Map<string, number>> {
        return await axios
            .get(`${Settings.adminBackend}/sessionsperservice`)
            .then((response) => {
                if (response.status === 200) {
                    return response.data as Map<string, number>
                } else {
                    return {} as Map<string, number>
                }
            })
            .catch(() => {
                return {} as Map<string, number>
            })
    }

    public static async getAllBackups(): Promise<string[]> {
        return await axios
            .get(`${Settings.adminBackend}/backups`)
            .then((response) => {
                if (response.status === 200) {
                    return response.data as string[]
                } else {
                    return []
                }
            })
            .catch(() => {
                return []
            })
    }

    public static async backup(desc?: string): Promise<Response> {
        return await axios
            .post(`${Settings.adminBackend}/backup`, null, {params: {description: desc}})
            .then((response) => {
                if (response.status === 200) {
                    return Response.buildSuccesResponse(
                        `Backup ${desc} created successfully`,
                        `Backup ${desc} succesvol aangemaakt`);
                } else {
                    return Response.buildFailedResponse(
                        response.status,
                        `Expected status 200 but got ${response.status}`,
                        `Verwachte status is 200, maar ontvangen status is ${response.status}`
                    );
                }
            })
            .catch((error: AxiosError) => {
                return AxiosHelper.handleErrorResponse(error)
            })

    }

    public static async restoreBackup(restoreString: string): Promise<Response> {
        return axios
            .post(`${Settings.adminBackend}/restore-backup`, null, {params: {fileName: restoreString}})
            .then((response) => {
                if (response.status === 200) {
                    return Response.buildSuccesResponse(
                        `Backup ${restoreString} restored successfully`,
                        `Backup ${restoreString} succesvol teruggezet`);
                } else {
                    return Response.buildFailedResponse(
                        response.status,
                        `Expected status 200 but got ${response.status}`,
                        `Verwachte status is 200, maar ontvangen status is ${response.status}`
                    );
                }
            })
            .catch((error: AxiosError) => {
                return AxiosHelper.handleErrorResponse(error)
            });
    }

    public static async restoreBackupFile(restoreFile: File): Promise<Response> {
        const formData = new FormData();
        formData.append('file', restoreFile);
        return axios
            .post(`${Settings.adminBackend}/restore-backup-file`, formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            })
            .then((response) => {
                if (response.status === 200) {
                    return Response.buildSuccesResponse(
                        `Backup ${restoreFile.name} restored successfully`,
                        `Backup ${restoreFile.name} succesvol teruggezet`);
                } else {
                    return Response.buildFailedResponse(
                        response.status,
                        `Expected status 200 but got ${response.status}`,
                        `Verwachte status is 200, maar ontvangen status is ${response.status}`
                    );
                }
            })
            .catch((error: AxiosError) => {
                return AxiosHelper.handleErrorResponse(error)
            });
    }

    public static async delete(description: string): Promise<Response> {
        return axios.post(`${Settings.adminBackend}/backup/delete`, null, {params: {description: description}})
            .then((response) => {
                if (response.status === 204) {
                    return Response.buildSuccesResponse(
                        `Backup with description  ${description} deleted successfully`,
                        `Backup met omschrijving  ${description} succesvol verwijderd`)
                } else {
                    return Response.buildFailedResponse(
                        response.status,
                        `Expected status 204 but got ${response.status}`,
                        `Verwachte status is 204, maar ontvangen status is ${response.status}`
                    )
                }
            })
            .catch((error: AxiosError) => {
                return AxiosHelper.handleErrorResponse(error)
            })
    }
}
