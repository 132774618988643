import { defineComponent, onMounted, ref } from 'vue'
import AdminController from '@/controllers/AdminController'

export default defineComponent({
  name: 'SessionsPerService',
  setup () {
    const sessionsPerService = ref(new Map<string, number>())
    onMounted(async () => {
      sessionsPerService.value = await AdminController.getSessionsPerService()
    })
    return {
      sessionsPerService
    }
  }
})
